import { Toast, Dialog } from 'vant'
import { getUserAccessValid } from '@/apis'
import {
  RUI_TU_SIGN,
  VIDEO_TYPES,
  IMAGE_TYPES,
  FILE_TYPE_IMAGE_DESC,
  FILE_TYPE_VIDEO_DESC,
  FILE_TYPE_OTHER_DESC,
} from '@/constant'
import { nanoid } from 'nanoid'

export const checkUploadImages = async imgs => {
  if (imgs.length < 1) {
    Toast.fail('请上传图片')
    return false
  }
  const isUploading = imgs.some(img => img.status === 'uploading')
  if (isUploading) {
    Toast.loading({
      message: '图片正在上传中...',
      forbidClick: true,
      loadingType: 'spinner',
    })
    return false
  }
  let deviceInfo = ''
  try {
    deviceInfo = await getDeviceInfo()
  } catch {
    deviceInfo = ''
  }
  const picUrlList = [] // 图片地址
  const picOssUrlList = [] // 图片OSS地址
  const deviceList = [] // 设备信息
  const picFormatList = [] // 文件格式
  const picSizeList = [] // 文件大小
  const picDurationList = [] // 时长
  const picWidthList = [] // 宽度
  const picHeightList = [] // 高度
  const picCreateList = [] // 文件创建时间
  const chooseFileTimeList = [] // 文件选择时间
  const uploadStartTimeList = [] // 开始上传时间
  const uploadEndTimeList = [] // 结束上传时间
  let uploadImageNum = 0 // 上传图片数量

  for (let i = 0; i < imgs.length; i++) {
    const imgInfo = imgs[i]
    if (imgInfo.status === 'done') {
      const { url, lastModified, duration, width, height, ossUrl, chooseFileTime, uploadStartTime , uploadEndTime} = imgInfo
      const size = (imgInfo.file.size / 1024 / 1024).toFixed(2) // 转成 M
      picUrlList.push(url)
      picOssUrlList.push(ossUrl)
      picCreateList.push(lastModified || 0)
      deviceList.push(deviceInfo)
      picFormatList.push(imgInfo.file.type || '')
      picSizeList.push(size)
      picDurationList.push((duration && duration.toFixed(2)) || '0')
      picWidthList.push(width || 0)
      picHeightList.push(height || 0)
      chooseFileTimeList.push(chooseFileTime || 0)
      uploadStartTimeList.push(uploadStartTime || 0)
      uploadEndTimeList.push(uploadEndTime || 0)
      if(/image/.test(imgInfo.file.type || '')) {
        uploadImageNum++
      }
    }
  }

  return {
    picUrlList,
    picOssUrlList,
    picCreateList,
    deviceList,
    picFormatList,
    picSizeList,
    picDurationList,
    picWidthList,
    picHeightList,
    uploadStartTimeList,
    uploadEndTimeList,
    chooseFileTimeList,
    uploadImageNum,
  }
}

export const copyText = (input, {target = document.body} = {}) => {
	const element = document.createElement('textarea');
	const previouslyFocusedElement = document.activeElement;

	element.value = input;

	// Prevent keyboard from showing on mobile
	element.setAttribute('readonly', '');

	element.style.contain = 'strict';
	element.style.position = 'absolute';
	element.style.left = '-9999px';
	element.style.fontSize = '12pt'; // Prevent zooming on iOS

	const selection = document.getSelection();
	const originalRange = selection.rangeCount > 0 && selection.getRangeAt(0);

	target.append(element);
	element.select();

	// Explicit selection workaround for iOS
	element.selectionStart = 0;
	element.selectionEnd = input.length;

	let isSuccess = false;
	try {
		isSuccess = document.execCommand('copy');
	} catch(e) {
    console.log(e)
  }

	element.remove();

	if (originalRange) {
		selection.removeAllRanges();
		selection.addRange(originalRange);
	}

	// Get the focus back on the previously focused element, if any
	if (previouslyFocusedElement) {
		previouslyFocusedElement.focus();
	}
  if (isSuccess) {
    Toast.success('复制成功')
  }
	return isSuccess;
}

export const getUrlParams = urlData => {
  const params = {}
  Object.keys(urlData).forEach(item => {
    const val = Number(urlData[item])
    let value = isNaN(val) ? urlData[item] : val
    value = value === 'null' ? null : value
    params[item] = value
  })
  return params
}

export const userAccessValid = async urlParams => {
  const { jobId, cityId, taskId, from_channel: channel, userTaskId } = urlParams
  if (channel && channel === RUI_TU_SIGN) {
    return true
  }
  const params = { jobId, cityId, taskId, userTaskId }
  try {
    const res = await getUserAccessValid(params)
    if (res) {
      let options = {
        message: res.msg,
        confirmButtonText: '下次再来哦！',
      }
      if (res.code === 0) {
        return true
      }
      if (res.status === 401) {
        options.message = res.data.msg
      }
      await Dialog.alert(options)
      // window.close()
      return false
    }
  } catch (err) {
    await Dialog.alert({
      message: err || '鉴权接口请求异常',
    })
    // window.close()
    return false
  }
}

export const getImagePix = imgfile => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = file => {
      const oImg = new Image()
      oImg.src = file.target.result
      oImg.onload = function () {
        resolve({
          width: oImg.width,
          height: oImg.height,
        })
      }
      oImg.onerror = e => {
        reject(e)
      }
    }
    reader.onerror = () => {
      resolve({
        width: 0,
        height: 0,
      })
    }
    reader.readAsDataURL(imgfile)
  })
}

export const getVideoPix = file => {
  return new Promise((resolve, reject) => {
    try {
      // 如果在5s内没获取到视频信息，则上报异常并返回默认信息
      const timer = setTimeout(() => {
        resolve({
          width: 0,
          height: 0,
          duration: 0,
        })
      }, 5000)
      window.URL = window.URL || window.webkitURL
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src)
        clearTimeout(timer)
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration,
        })
      }
      video.src = URL.createObjectURL(file)
    } catch (err) {
      reject(err)
    }
  })
}

export const getMediaDimensions = async (file, type) => {
  let result = {}
  if (type && type.includes('image')) {
    result = await getImagePix(file.file)
  }
  if (type && type.includes('video')) {
    result = await getVideoPix(file.file)
  }
  return result
}

export const getFileType = file => {
  const { type } = file
  if (type && type.includes('image')) {
    return FILE_TYPE_IMAGE_DESC
  }
  if (type && type.includes('video')) {
    return FILE_TYPE_VIDEO_DESC
  }
  return FILE_TYPE_OTHER_DESC
}

export const isUsableImage = type => {
  return IMAGE_TYPES.includes(type)
}

export const isUsableVideo = type => {
  return VIDEO_TYPES.includes(type)
}

export const checkFileOverSize = (file, maxImageSize, maxVideoSize) => {
  // 检查文件大小, 单张图片不大于50M，单个视频不大于500M
  const { type, size } = file.file
  if (type && type.includes('image') && size > maxImageSize) {
    return true
  }
  if (type && type.includes('video') && size > maxVideoSize) {
    return true
  }
  return false
}

export const getFileSizeRange = realSize => {
  if (realSize < 10) {
    return '0-10M'
  }
  if (realSize < 20) {
    return '10M-20M'
  }
  if (realSize < 30) {
    return '20M-30M'
  }
  if (realSize < 50) {
    return '30M-50M'
  }
  if (realSize < 100) {
    return '50M-100M'
  }
  return '100M+'
}

export const getUploadTimeRange = (end = 0, start = 0) => {
  const time = (end - start) / 1000
  if (time < 10) {
    return '0-10s'
  }
  if (time < 20) {
    return '10-20s'
  }
  if (time < 30) {
    return '20-30s'
  }
  if (time < 60) {
    return '30-60s'
  }
  if (time < 120) {
    return '1-2min'
  }
  if (time < 300) {
    return '2-5min'
  }
  return '5min+'
}

export const getTaskFinishTimeRange = (end = 0, start = 0) => {
  const time = (end - start) / 1000
  if (time < 60) {
    return '0-1min'
  }
  if (time < 120) {
    return '1-2min'
  }
  if (time < 180) {
    return '2-3min'
  }
  if (time < 300) {
    return '3-5min'
  }
  if (time < 600) {
    return '5-10min'
  }
  if (time < 900) {
    return '10-15min'
  }
  if (time < 1200) {
    return '15-20min'
  }
  return '20min+'
}

export const getDeviceInfo = async () => {
  let res
  if (res) {
    return res.model
  } else {
    return getMobileDevice()
  }
}

/*判断客户端*/
export const judgeClient = () => {
  let client = '';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
    client = 'iOS';
  } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
    client = 'Android';
  } else {
    client = 'PC';
  }
  return client;
}

function getMobileDevice() {
  const app = navigator.appVersion
  //    根据括号进行分割
  const left = app.indexOf('(')
  const right = app.indexOf(')')
  const str = app.substring(left + 1, right)
  const strArr = str.split(';')
  // 手机型号--苹果 iPhone
  const mobileIphone = strArr[0]
  // 手机型号--安卓
  const mobileAndroid = strArr[2]
  // 红米手机等特殊型号处理 匹配字符
  const res = /Android/
  const result = res.test(mobileAndroid)
  const phoneVersion = strArr[0]
  // 根据设备型号判断设备系统
  if (phoneVersion === 'iPhone') {
    return mobileIphone
  } else if (phoneVersion === 'Linux') {
    if (result) {
      return strArr[4].substring(0, 9)
    } else {
      return mobileAndroid.substring(0, 9)
    }
  } else if (phoneVersion === 'iPad') {
    return strArr[0]
  } else {
    return getBrowser()
  }
}

function getBrowser() {
  const userAgent = navigator.userAgent.toLowerCase()
  const browserInfo = {}
  const browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome: userAgent.indexOf('chrome') > -1 && userAgent.indexOf('safari') > -1, // Chrome浏览器
    Firefox: userAgent.indexOf('firefox') > -1, // 火狐浏览器
    Opera: userAgent.indexOf('opera') > -1, // Opera浏览器
    Safari: userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1, // safari浏览器
    Edge: userAgent.indexOf('edge') > -1, // Edge浏览器
    QQBrowser: /qqbrowser/.test(userAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(userAgent), // 微信浏览器
  }
  for (let i in browserArray) {
    if (browserArray[i]) {
      let versions = ''
      if (i === 'IE') {
        versions = userAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)[2]
      } else if (i === 'Chrome') {
        for (let mt in navigator.mimeTypes) {
          // 检测是否是360浏览器(测试只有pc端的360才起作用)
          if (navigator.mimeTypes[mt]['type'] === 'application/360softmgrplugin') {
            i = '360'
          }
        }
        versions = userAgent.match(/chrome\/([\d.]+)/)[1]
      } else if (i === 'Firefox') {
        versions = userAgent.match(/firefox\/([\d.]+)/)[1]
      } else if (i === 'Opera') {
        versions = userAgent.match(/opera\/([\d.]+)/)[1]
      } else if (i === 'Safari') {
        versions = userAgent.match(/version\/([\d.]+)/)[1]
      } else if (i === 'Edge') {
        versions = userAgent.match(/edge\/([\d.]+)/)[1]
      } else if (i === 'QQBrowser') {
        versions = userAgent.match(/qqbrowser\/([\d.]+)/)[1]
      }
      browserInfo.type = i
      browserInfo.versions = parseInt(versions)
    }
  }
  return browserInfo.type + '/' + browserInfo.versions
}

export const getUid = () => nanoid()

export const getHostInfo = () => {
  try {
    return getMobileDevice()
  } catch (e) {
    return ''
  }
}

const sysmemo =
  typeof window.performance !== 'undefined' && typeof window.performance.memory !== 'undefined'
    ? window.performance.memory
    : null

const getSemanticSize = size => (size / 1024 / 1024).toFixed(2) + 'M'

export const getJSHeapRatio = () => {
  if (!sysmemo) return ''

  const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = sysmemo

  return `${getSemanticSize(jsHeapSizeLimit)} - ${getSemanticSize(totalJSHeapSize)} - ${getSemanticSize(
    usedJSHeapSize
  )}`
}
