export const RUI_TU_SIGN = 'bestbd' // 锐兔App的标记
export const PASSPORT_SIGN = 'passport' // passport 用户来源标记
export const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
export const VIDEO_TYPES = ['video/mp4', 'video/avi', 'video/wmv']

export const ACCEPT_IMAGE = ['image/png', 'image/jpeg', 'image/jpg']
export const ACCEPT_VIDEO = ['video/mp4', 'video/avi', 'video/wmv']

export const MAX_IMAGE_SIZE = 1024 * 1024 * 50 // 单张图片最大大小
export const MAX_VIDEO_SIZE = 1024 * 1024 * 500 // 单个视频最大大小
export const MAX_TOTAL_FILE_SIZE = 1024 * 1024 * 500 // 一次上传最大总的文件大小（暂没有实现）

export const FILE_TYPE_IMAGE_DESC = '图片'
export const FILE_TYPE_VIDEO_DESC = '视频'
export const FILE_TYPE_UNKNOW = '未知' // fix 小米录频获取不到类型，但文件是mp4
export const FILE_TYPE_OTHER_DESC = '文件'

export const UPLOAD_FILE_UNIQUEID_LIST = 'UPLOAD_FILE_UNIQUEID_LIST'

export const ACTIVITY_ORDER_UNIQUEID_LIST = 'ACTIVITY_ORDER_UNIQUEID_LIST'

export const KL_RESEARCH_TASK = 'KL_RESEARCH_TASK'

export const ACTIVITY_ORDER_TYPE = 9

export const SYSTEMTIME_DURATION = 10000

export const FEEDBACK_OTHERS = '其他情况（请描述详情）'
